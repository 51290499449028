<template>
  <el-table-column
    v-if="showComponent"
    label=""
    :width="showOnMobile ? 62 : 150">
    <div
      slot-scope="slotScope"
      class="table-items-list-item">
        <i
          @click="deleteFunction(slotScope.row, $event)"
          :class="{ 'el-icon-delete': types.includes('delete') }"
          class="icon-col grow" />
        <i
          @click="mailFunction(slotScope.row, $event)"
          :class="{ 'el-icon-message': types.includes('mail') }"
          class="icon-col grow" />
        <i
          @click="updateFunction(slotScope.row, $event)"
          :class="{ 'el-icon-edit': types.includes('edit') }" 
          class="grow" />
    </div>
  </el-table-column>
</template>

<script>
export default {
  name: 'ButtonColumn',
  props: {
    types: Array, // Either 'edit' or 'delete', change the button color and the icon.
    buttonTitle: String,
    showOnMobile: Boolean, // If true show the component on mobile only, if false show it on desktop only.
    updateFunction: Function,
    deleteFunction: Function,
    mailFunction: Function,
  },
  data() {
    return {
      mobileWindowMaxWidth: 991,
      windowInnerWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener('resize', this.resize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
  computed: {
    // Based on the showOnMobile prop and the size of the window, return true if the component has to be shown of false otherwise.
    showComponent() {
      const isWebsiteOnMobileMode = this.windowInnerWidth <= this.mobileWindowMaxWidth;
      return (isWebsiteOnMobileMode && this.showOnMobile) || (!isWebsiteOnMobileMode && !this.showOnMobile);
    },
  },
  methods: {
    resize() {
      this.windowInnerWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon-table-item {
    padding: 0px;
  }

  .icon-col {
    margin-right: 20px;
  }

  .grow {
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.5);
      cursor: pointer;
    }
    
  }

  .el-icon-delete {
    color: #fb404b;
  }

  .el-icon-message {
    color: #518bce;
  }

  .el-icon-edit {
    color: #9368e9;
  }
</style>
