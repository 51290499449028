<template>
  <div>
    <crud-list
      :fetch-data="fetchUsers"
      ref="crudList"
      title="Utilisateurs"
      :filters="filters"
      :createAllowedUser="createAllowedUser"
      :defaultSort="{prop: 'lastName', order: 'ascending'}"
      @sort-change="sortChange"
      @search="searchUser"
      @create="createUser"
      @filtersCrudList="filterUsers"
      :showSearch="true"
    >
    <button-column
      type="edit"
      button-title="Modifier l'utilisateur"
      :show-on-mobile="true"
      :update-function="updateUser" />
      <el-table-column
        prop="uuid"
        label="ID unique"
        min-width="200"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="lastName"
        label="Nom"
        min-width="150"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="firstName"
        label="Prénom"
        min-width="150"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        min-width="250"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="affiliation"
        label="Affiliation"
        min-width="160"
      >
      </el-table-column>
      <el-table-column
        prop="homeOrganization"
        label="Organisation"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        prop="homeOrganizationType"
        label="Type d'organisation"
        min-width="210"
      >
      </el-table-column>
      <button-column
        :types="['delete','edit','mail']"
        button-title="Opérations"
        :show-on-mobile="false"
        :delete-function="deleteUser"
        :mail-function="sendMail"
        :update-function="updateUser" />
    </crud-list>
  </div>
</template>
<script>
import ROLES from '@/enums/Roles';
import CrudList from '@/components/Table/CrudList.vue';
import ButtonColumn from '@/components/Table/ButtonColumn.vue';

export default {
  components: {
    CrudList,
    ButtonColumn,
  },
  data() {
    return {
      users: [],
      createAllowedUser: [ROLES.ADMINISTRATOR],
      sort: '&sort=lastName,asc',
      search: '',
      filters: {
        userStatus: ''
      },
    }
  },
  methods: {
    fetchUsers(pageNumber, pageSize) {
      let url = `${process.env.VUE_APP_IDP_API_URL}/api/users?page=${pageNumber - 1}&size=${pageSize}`;
      // remove sort when searching
      if (this.search !== '') {
        url += `${this.search}`;
      } else {
        url += `${this.sort}`;
      }

      if (this.filters.userStatus !== '') {
        url += `&userStatus=${this.filters.userStatus}`;
      }

      return this.$http.get(
        url
      ).then((res) => {
        if (res.data) {
          let usersRaw = res.data.content ? res.data.content : [];
          let page = null
          if (res.data.pageable) {
            page = {
              totalElements: res.data.totalElements,
            };
          } else {
            page = {
              totalElements: 0,
            };
          }
            
          return {
            records: usersRaw,
            page: page,
          }
        } 
      })
    },
    filterUsers(filters) {
      this.filters.userStatus = `${filters.userStatus}`;
      this.$refs.crudList.fetch();
    },
    updateUser(row, event) {
      this.$router.push({ name: 'userDetails', params: {id: row.id} });
    },
    createUser() {
      this.$router.push({ name: 'userCreation' });
    },
    deleteUser(row, event) {
      const url = `${process.env.VUE_APP_IDP_API_URL}/api/users/${row.id}`;
      return this.$http.delete(
        url
      ).then((res) => {
        if (res.status === 202) {
          this.$refs.crudList.fetch();
        }
      }).catch((error) => {
        this.$notify({
          message: "Une erreur est survenue. Veuillez contacter le support.",
          icon: 'add_alert',
          type: 'error',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
      })
    },
    sendMail(row, event) {
      const url = `${process.env.VUE_APP_IDP_API_URL}/api/users/reset-password`;
      return this.$http.post(
        url,
        {
          email: row.email,
        },
      ).then((res) => {
        if (res.status === 201) {
          this.$notify({
            message: "Le mail de réinitialisation de mot de passe a bien été envoyé.",
            icon: 'add_alert',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
          this.$refs.crudList.fetch();
        }
      }).catch((error) => {
        this.$notify({
          message: "Une erreur est survenue. Veuillez contacter le support.",
          icon: 'add_alert',
          type: 'error',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
      })
    },
    sortChange({ column, prop, order }) {
      const orderString = order === 'ascending' ? 'asc' : 'desc';
      this.sort = `&sort=${prop},${orderString}`;
      this.$refs.crudList.fetch();
    },
    searchUser(search) {
      if (search && search !== '' && search.length >= 2) {
        this.search = `&search=${search}`;
        this.$refs.crudList.fetch();
      } else {
        this.search = '';
        this.$refs.crudList.fetch();
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
