<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-6 ml-auto mr-auto">
              <el-card shadows="always">
                <h2>Changement de mot de passe</h2>
                <div class="input-container">
                  <label>Nouveau mot de passe</label>
                  <el-input
                    v-model="password"
                    placeholder="Entrez mot de passe"
                    show-password
                  >
                  </el-input>
                </div>
                <div class="input-container">
                  <label>Répéter votre mot de passe</label>
                  <el-input
                    v-model="passwordRepet"
                    placeholder="Entrez mot de passe"
                    show-password
                  >
                  </el-input>
                </div>
                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                <button class="btn" @click="tryChangePassword">Changer</button>
              </el-card>
            </div>
          </div>
        </div>
        <content-footer />
        <div
          class="full-page-background"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Input } from 'element-ui';
import { Button, InfoSection } from '@/components/Template/index';
import ContentFooter from "@/layout/ContentFooter.vue";

export default {
  components: {
    ContentFooter,
    InfoSection,
    [Card.name]: Card,
    [Button.name]: Button,
    [Input.name]: Input,
  },
  props: ['key'],
  data() {
    return {
      password: null,
      passwordRepet: null,
      errorMessage: null,
    }
  },
  methods: {
    tryChangePassword() {
      if (this.password === null) {
        this.errorMessage = "Veulliez entrez un mot de passe.";
      } else if (this.passwordRepet === null) {
        this.errorMessage = "Veuillez repéter votre mot de passe.";
      } else if (this.password !== this.passwordRepet) {
        this.errorMessage = "Les deux mots de passe ne sont pas similaire";
      } else {
        this.updatePassword();
      }
    },
    updatePassword() {
      this.$http.put(
        `${process.env.VUE_APP_IDP_API_URL}/api/users/update-password`,
        {
          registrationKey: this.key,
          password: this.password,
        },
      )
      .then(async (res) => {
        this.$notify({
          message: "Modification de votre mot de passe réalisé avec succès",
          icon: 'add_alert',
          type: 'success',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
        // await sleep(2000); // leave time to see notification
        window.location = process.env.VUE_APP_SP_URL;
      })
      .catch((error) => {
        let message;
        if (error.response.status === 404) {
          message = "La demande de réinitialisation de mot de passe n'existe pas dans le système. Veuillez contacter le support ou recommencer la procédure.";
        } else {
          message = "Une erreur serveur est survenu, veuillez contacter le support";
        }
        this.$notify({
          message: message,
          icon: 'add_alert',
          type: 'error',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.input-container {
  margin-bottom: 20px;
}
</style>
