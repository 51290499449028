<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-6 ml-auto mr-auto">
              <el-card shadows="always">
                <h2>Demande de réinitialisation de mot de passe</h2>
                <div class="input-container">
                  <label>Votre adresse mail</label>
                  <el-input
                    v-model="email"
                    placeholder="Entrez votre adresse mail"
                  >
                  </el-input>
                </div>
                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                <button class="btn" @click="trySendRequest">Envoyer</button>
              </el-card>
            </div>
          </div>
        </div>
        <content-footer />
        <div
          class="full-page-background"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Input } from 'element-ui';
import { Button, InfoSection } from '@/components/Template/index';
import ContentFooter from "@/layout/ContentFooter.vue";

export default {
  components: {
    ContentFooter,
    InfoSection,
    [Card.name]: Card,
    [Button.name]: Button,
    [Input.name]: Input,
  },
  data() {
    return {
      email: null,
      errorMessage: null,
    }
  },
  methods: {
    trySendRequest() {
      if (this.email === null) {
        this.errorMessage = "Veulliez entrez une adresse mail.";
      } else {
        this.sendRequest();
      }
    },
    sendRequest() {
      this.$http.post(
        `${process.env.VUE_APP_IDP_API_URL}/api/users/reset-password`,
        {
          email: this.email,
        },
      )
      .then(async (res) => {
        this.$notify({
          message: "Votre demande a bien été envoyée. Vous devriez recevoir un email à l'adresse donnée.",
          icon: 'add_alert',
          type: 'success',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
        // await sleep(2000); // leave time to see notification
        window.location = process.env.VUE_APP_SP_URL;
      })
      .catch((error) => {
        let message;
        if (error.response.status === 404) {
          message = "Cette adresse mail n'existe pas dans le système. Veuillez effectuer une requête au support afin d'obtenir un compte valide.";
        } else {
          message = "Une erreur serveur est survenu, veuillez contacter le support";
        }
        this.$notify({
          message: message,
          icon: 'add_alert',
          type: 'error',
          horizontalAlign: 'right',
          verticalAlign: 'top',
        });
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.input-container {
  margin-bottom: 20px;
}
</style>
