// Pages
import UserDetails from '@/views/UserDetails.vue';
import Users from '@/views/Users.vue';

// GeneralViews
import NotFound from '@/views/NotFoundPage.vue';
import NotAllowedPage from '@/views/NotAllowedPage.vue';

import ResetPassword from '@/views/ResetPassword.vue';
import UpdatePassword from '@/views/UpdatePassword.vue';
import ChangePassword from '@/components/auth/ChangePassword.vue';

// Layout
import DashboardLayout from '@/layout/DashboardLayout.vue';

// Roles
import ROLES from '@/enums/Roles'

// Password reset pages

const notAllowed = {
  path: '/not-allowed',
  name: 'NotAllowed',
  component: NotAllowedPage,
  permissions: {
    lvl: 0,
  }
};

const resetPassword = {
  path: '/reset-password',
  name: 'ResetPassword',
  component: ResetPassword,
  permissions: {
    lvl: 0,
  }
}

const updatePassword = {
  path: '/update-password/:key',
  name: 'UpdatePassword',
  component: UpdatePassword,
  props: true,
  permissions: {
    lvl: 0,
  }
}

const changePassword = {
  path: '/change-password/:key',
  name: 'ChangePassword',
  component: ChangePassword,
  props: true,
  permissions: {
    lvl: 0,
  }
}

const routes = [
  notAllowed,
  resetPassword,
  changePassword,
  updatePassword,
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '',
        redirect: '/users',
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        permissions: {
          roles: [ROLES.ADMINISTRATOR],
        },
      },
      {
        path: 'users/:id',
        name: 'userDetails',
        component: UserDetails,
        permissions: {
          roles: [ROLES.ADMINISTRATOR],
        },
      },
      {
        path: 'users/creation',
        name: 'userCreation',
        component: UserDetails,
        permissions: {
          roles: [ROLES.ADMINISTRATOR],
        },
      },
    ],
  },
  { path: '*', component: NotFound },
];

export default routes;
