<template>
  <div class="sidebar" :data-color="backgroundColor" :data-active-color="activeColor">
    <div class="logo">
      <router-link
        class="simple-text logo-mini"
        to="/"
      >
        <div class="logo-img">
          <img
            :src="logo"
            alt=""
          >
        </div>
      </router-link>
      <router-link
        class="simple-text logo-normal"
        to="/"
      >
        {{ title }}
      </router-link>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinksAllowed"
            :key="link.name + index"
            :link="link"
            :title="link.name"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
              :title="subLink.name"
            ></sidebar-item>
          </sidebar-item>
          <li>
            <a :href="supportUrl" target="_blank">
              <i class="fa fa-life-ring"></i>
              <p>Support</p>
            </a>
          </li>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import "perfect-scrollbar/dist/css/perfect-scrollbar.css";
import { isAllowed } from '@/services/AuthService';
import { domainAbbreviationsIcons } from "@/utils/domainAbbreviationsIcons";
import sidebarLinks from '@/sidebarLinks';

export default {
  components: {
    domainAbbreviationsIcons
  },
  props: {
    title: {
      type: String,
      default: "Vue PDP Pro",
      description: "Sidebar title"
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)"
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        "Sidebar active text color (primary|info|success|warning|danger)"
    },
    logo: {
      type: String,
      default: "static/img/vue-logo.png",
      description: "Sidebar Logo"
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "Sidebar links. Can be also provided as children components (sidebar-item)"
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      domains: [],
      sidebarLinksAllowed: sidebarLinks,
      supportUrl: process.env.VUE_APP_SUPPORT_URL,
    };
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    isAllowed,
    async initScrollBarAsync() {
      let isWindows = navigator.platform.startsWith("Win");
      if (!isWindows) {
        return;
      }
      const PerfectScroll = await import("perfect-scrollbar");
      PerfectScroll.initialize(this.$refs.sidebarScrollArea);
    },
    domainAbbreviationsIcons,
    getAllowedLinks(){
      this.sidebarLinksAllowed = sidebarLinks.filter((link) => isAllowed(link));
    },
  },
  mounted() {
    this.initScrollBarAsync();
    this.getAllowedLinks();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
  .simple-text {
    text-align: center;
  }
}
</style>
