import routes from "@/routes/routes";
import store from "@/store";

export const isAllowed = (to) => {
  const rolesNeeded = getRolesNeeded(to);

  // PERMISSION DEFINED BY ROLES
  if (rolesNeeded && rolesNeeded.roles) {
    return isUserAllowedByRoles(rolesNeeded.roles);
  }

  // PERMISSION DEFINED BY LVL
  else if (rolesNeeded && rolesNeeded.lvl !== undefined) {
    return isUserAllowedByLvl(rolesNeeded);
  }
  // PERMISSION DEFINED BY IS CONNECTED
  else if (rolesNeeded && rolesNeeded.auth) {
    // The check if token expired done in router
    return store.getters["account/token"] ? true : false; // return true if have a token
  }
  // NO PERMISSION DEFINED
  else {
    return false; // No public so return false
  }
};

export const isUserAllowedByRoles = (rolesNeeded) => {
  const rolesUser = store.getters["account/roles"];
  return rolesNeeded.filter((role) => rolesUser.includes(role)).length > 0;
};

export const isUserAllowedByLvl = (rolesNeeded) => {
  if (rolesNeeded.lvl === 0) {
    return true;
  }

  const rolesUser = store.getters["account/roles"];
  let isLvlEnough = false;
  rolesUser.forEach((role) => {
    if (role.value >= rolesNeeded.lvl) {
      isLvlEnough = true;
    }
  });
  return isLvlEnough;
};

export const getRolesNeeded = (to) => {
  const routesFormatted = formatRoutes(routes, '');
  const toArray = to.path.split('/').filter(e => e !== '');
  const routesPossible = routesFormatted.filter((route) => {
    const routeArray = route.path.split('/').filter(e => e !== '');
    return routeArray.length === toArray.length && !routeArray.find((element, index) => !element.startsWith(':') && element !== toArray[index]);
  });
  return routesPossible.length > 0 ? routesPossible[0].permissions : null;
};

export const formatRoutes = (routes, parent) => {
  return routes.flatMap((route) => {
    if (route.children) {
      return formatRoutes(route.children, route.path);
    } else {
      return [
        {
          ...route,
          path: parent + route.path,
        },
      ];
    }
  });
};

export const verifyAndRenewToken = async () => {
  if (store.getters["account/token"] && new Date().getTime() > store.getters["account/expiration"]) {
    store.commit("account/clear"); // We need to clear because we need a new token

    try {
      await store.dispatch("account/login");

      if (store.getters["account/token"]) {
        Vue.prototype.$notifications.notify({
          message: "Vous avez été reconnecter!",
          title: "Session expirée",
          icon: "fa fa-exclamation-triangle",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "warning",
          timeout: 10000
        });
      } else {
        throw "Token expired and not authenticated";
      }
    } catch (error) {
      console.error("Shib session is not available", error);

      Vue.prototype.$notifications.notify({
        message:
          "Vous avez été déconnecté car votre session a expiré, veuillez vous reconnecter!",
        title: "Session expirée",
        icon: "fa fa-exclamation-triangle",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "danger",
        timeout: 10000
      });
    }
  }
};
