<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using:
                data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-6 ml-auto mr-auto">
              <info-section
                class="mt-5"
                type="danger"
                title="Marketing"
                description="Non autorisé à accéder à l'application"
                icon="nc-icon nc-alert-circle-i"
              >
                <h2 class="text-white mt-3">
                  Vous n'avez pas les droits pour accéder à cette application
                </h2>
                <small class="text-white">Cette application est réservée aux administrateurs de la CIIP, si vous en faites partie, veuillez demander au <a :href="supportURL">support</a> de vous rajouter les droits d'accès.
                </small>
              </info-section>
            </div>
          </div>
        </div>
        <content-footer />
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/jan-sendereks.jpg) "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection } from '@/components/Template/index';
import ContentFooter from "@/layout/ContentFooter.vue";

export default {
  components: {
    ContentFooter,
    InfoSection,
    [Button.name]: Button,
  },
  data() {
    return {
      supportURL: `${process.env.VUE_APP_SUPPORT_URL}&title=Demande d'autorisation&message=Bonjour, je suis un administrateur de la CIIP et je désirerais avoir accès à IDP en mode administration, merci.`,
    }
  },
  beforeDestroy() {
  },
  methods: {
  },
};
</script>
<style>
</style>
