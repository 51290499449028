import Vue from "vue";
import "./pollyfills";
import VueRouter from "vue-router";
import VueRouterPrefetch from "vue-router-prefetch";
import Notifications from "vue-notifyjs";
import store from "./store";

import attributesFr from "vee-validate/dist/locale/fr";
import VeeValidate, { Validator } from "vee-validate";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import App from "./App.vue";
import { verifyAndRenewToken } from "@/services/AuthService";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./layout/Sidebar";
import initProgress from "./progressbar";

// library imports
import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/styles.scss";

import sidebarLinks from "./sidebarLinks";
// import "./registerServiceWorker";

// plugin setup
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(Notifications);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });
Vue.use(VeeValidate);
Vue.use(Element);
Validator.localize("fr", attributesFr);
locale.use(lang);

// Router and auth setup
import router from "./router/router";

// Axios http requests and set as $http provider globally
import axios from "axios";

const instance = axios.create();
instance.interceptors.request.use(async function(config) {
  await verifyAndRenewToken();
  if (store.getters["account/token"]) {
    config.headers.Authorization = `Bearer ${store.getters["account/token"]}`;
  }

  return config;
});
Vue.prototype.$http = instance; // https://vuejs.org/v2/cookbook/adding-instance-properties.html#Real-World-Example-Replacing-Vue-Resource-with-Axios

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  beforeCreate() {
    this.$store.dispatch("initialiseStore");
  },
  render: (h) => h(App),
  router,
});
