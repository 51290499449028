import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=47281c28&scoped=true&"
import script from "./UserMenu.vue?vue&type=script&lang=js&"
export * from "./UserMenu.vue?vue&type=script&lang=js&"
import style0 from "./UserMenu.vue?vue&type=style&index=0&id=47281c28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47281c28",
  null
  
)

export default component.exports