<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="18" :md="16" :lg="12" :xl="12">
      <el-breadcrumb v-if="!isNewUser" separator-class="el-icon-arrow-right" class="breadcumb-user">
        <el-breadcrumb-item :to="{ name: 'users' }">Utilisateurs</el-breadcrumb-item>
        <el-breadcrumb-item> {{user.lastName}} {{user.firstName}}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card>
        <h5>{{ title }}</h5>
        <form>
          <div v-if="!isNewUser" class="form-group">
            <label class="control-label">UUID</label>
            <input
              v-model="user.uuid"
              class="form-control"
              type="text"
              name="uuid"
              disabled
            >
          </div>
          <div class="form-group">
            <label class="control-label">Prénom</label>
            <input
              v-model="user.firstName"
              v-validate="modelValidations.firstName"
              class="form-control"
              type="text"
              name="firstName"
            >
            <small
              v-show="firstName.invalid"
              class="text-danger"
            >
              Le prénom est requis.
            </small>
          </div>
          <div class="form-group">
            <label class="control-label">Nom</label>
            <input
              v-model="user.lastName"
              v-validate="modelValidations.lastName"
              class="form-control"
              type="text"
              name="lastName"
            >
            <small
              v-show="lastName.invalid"
              class="text-danger"
            >
              Le nom est requis.
            </small>
          </div>
          <div class="form-group">
            <label class="control-label">Email</label>
            <input
              v-model="user.email"
              v-validate="modelValidations.email"
              class="form-control"
              type="email"
              name="email"
            >
            <small
              v-show="email.invalid"
              class="text-danger"
            >
              L'email est requis.
            </small>
          </div>
          <div class="form-group">
            <label class="control-label">Affiliation</label>
            <el-select
              id="select-affiliation"
              v-model="user.affiliation"
              class="select-info col-md-12 select-one-line-per-item"
              size="large"
              placeholder="Sélectionner"
            >
              <el-option
                v-for="affiliation in affiliations"
                :key="affiliation"
                :label="affiliation"
                :value="affiliation"
              />
            </el-select>
          </div>
          <div class="form-group">
            <label class="control-label">Organisation</label>
            <el-select
              id="select-organisation"
              v-model="user.homeOrganization"
              class="select-info col-md-12 select-one-line-per-item"
              size="large"
              placeholder="Sélectionner"
            >
              <el-option
                v-for="organisation in organisations"
                :key="organisation"
                :label="organisation"
                :value="organisation"
              />
            </el-select>
          </div>
          <div class="form-group">
            <label class="control-label">Type d'organisation</label>
            <el-select
              id="select-type-organisation"
              v-model="user.homeOrganizationType"
              class="select-info col-md-12 select-one-line-per-item"
              size="large"
              placeholder="Sélectionner"
              disabled
            >
              <el-option
                v-for="organisation_type in organisation_types"
                :key="organisation_type"
                :label="organisation_type"
                :value="organisation_type"
              />
            </el-select>
          </div>
        </form>
        <p v-for="error in errorMessage" :key="error" class="error-message">
          {{ error }}
        </p>
        <div
          class="justify-content-center d-flex"
        >
          <span >&nbsp;</span>
          <p-button
            type="success"
            round
            @click.native="saveUser"
          >
            Enregistrer
          </p-button>
          <span>&nbsp;</span>
          <p-button
            v-if="!isNewUser"
            type="danger"
            round
            @click.native="deleteUser"
          >
            Supprimer
          </p-button>
          <span>&nbsp;</span>
          <p-button
            type="light"
            round
            @click.native="cancelHandler"
          >
            Annuler
          </p-button>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { Col, Row, Card, Breadcrumb} from 'element-ui';
import { mapFields } from 'vee-validate';
import swal from 'sweetalert2';
import ROLES from '@/enums/Roles';


export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Card.name]: Card,
    [Breadcrumb.name]: Breadcrumb,
  },
  created() {
    this.userId = this.$route.params.id;
    this.isNewUser = this.userId ? false : true;
    this.fetchUser();
  },
  data() {
    return {
      isNewUser: true,
      userId: null,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        affiliation: 'staff',
        homeOrganization: 'ciip.ch',
        homeOrganizationType: 'others'
      },
      organisations: ['ciip.ch','ext.ciip.ch'],
      organisation_types: ['others'],
      affiliations: ['staff','affiliate'],
      errorMessage: [],
      modelValidations: {
        firstName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        email: {
          required: true,
        },
      },
    }
  },
  computed: {
    ...mapFields(['firstName', 'lastName', 'email']),
    title() {
      let title = '';

      if (this.isNewUser) {
        title = 'Ajouter un nouvel utilisateur';
      } else {
        title = `Changer information utilisateur ${ this.user.firstName } ${this.user.lastName}`
      }

      return title;
    }
  },
  methods: {
    saveUser() {
      if (this.isNewUser) {
        this.createUser();
      } else {
        this.updateUser();
      }
    },
    fetchUser() {
      if(!this.isNewUser) {
        return this.$http.get(
          `${process.env.VUE_APP_IDP_API_URL}/api/users/${this.userId}`
        )
        .then((res) => {
          if(res.data) {
            this.user = res.data;
          }
        })
        .catch((error) => {
          this.$notify({
            message: "Une erreur est survenu lors de la récupération des informations de l'utilisateur, veuillez contacter un administrateur",
            type: 'error',
          })
        }); 
      }
    },
    createUser() {
      this.$validator.validateAll()
        .then((result) => {
          if(result) {
            this.$http.post(
              `${process.env.VUE_APP_IDP_API_URL}/api/users`,
              this.user,
            )
            .then((res) => {
              this.$notify({
                message: "L'ajout de l'utilisateur a été effectué avec succès ! Un e-mail lui a été envoyé afin de pouvoir créer un mot de passe.",
                icon: 'add_alert',
                type: 'success',
                horizontalAlign: 'right',
                verticalAlign: 'top',
              });
              this.$router.push({ name: "users" });
            })
            .catch((error) => {
              console.log(error.response);
              this.errorMessage = [error.response.data.message];
            })
          } else {
            this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
          }
        }
      );
    },
    updateUser() {
      this.$validator.validateAll()
        .then((result) => {
          if(result) {
            this.$http.put(
              `${process.env.VUE_APP_IDP_API_URL}/api/users/${this.user.id}`,
              this.user,
            )
            .then((res) => {
              this.$notify({
                message: "Modification de l'utilisateur réalisé avec succès",
                icon: 'add_alert',
                type: 'success',
                horizontalAlign: 'right',
                verticalAlign: 'top',
              });
              this.$router.push({ name: "users" });
            })
            .catch((error) => {
              this.errorMessage.push(error.message);
            })
          } else {
            this.errorMessage.push("Un ou plusieurs champs ne sont pas valide");
          }
        }
      );
    },
    deleteUser() {
      swal({
        title: "Êtes vous sûre?",
        text: `L'utilisateur va être supprimé!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Oui!",
        cancelButtonText: "Annuler!",
        buttonsStyling: false
      })
      .then((result) => {
        if (result) {
          this.$http.delete(
            `${process.env.VUE_APP_IDP_API_URL}/api/users/${this.user.id}`
          )
          .then((res) => {
            this.$notify({
              message: "Suppression de l'utilisateur réalisé avec succès",
              icon: 'add_alert',
              type: 'success',
              horizontalAlign: 'right',
              verticalAlign: 'top',
            });
            this.$router.push({ name: "users" });
          })
          .catch((error) => {
            this.errorMessage.push(error.message);
          })
        }
      });
    },
    cancelHandler() {
      this.$router.push({ name: "users" })
    },
  }
}
</script>
<style lang="scss" scoped>
.select-info:disabled {
  background: #dddddd;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
}
.breadcumb-user {
  margin-bottom: 20px;
}
</style>