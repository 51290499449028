<template>
  <div></div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {};
  },
  props: ['key'],
  mounted() {
    this.$http.get(
      `${process.env.VUE_APP_IDP_API_URL}/api/users/change-password`, {
        params: {
          token: this.key,
        },
      },
    )
    .then(async (res) => {
      if (res.status === 200) {
        window.location = res.headers.location;
      }
      /*this.$notify({
        message: "Modification de votre mot de passe réalisé avec succès",
        icon: 'add_alert',
        type: 'success',
        horizontalAlign: 'right',
        verticalAlign: 'top',
      });
      // await sleep(2000); // leave time to see notification
      window.location = process.env.VUE_APP_SP_URL;*/
    })
    .catch((error) => {
      this.$notify({
        message: "Une erreur serveur est survenu, veuillez contacter le support",
        icon: 'add_alert',
        type: 'error',
        horizontalAlign: 'right',
        verticalAlign: 'top',
      });
    })
  }
};
</script>
<style>
</style>