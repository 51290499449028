import Vue from "vue";
import jwt from "jsonwebtoken";
import axios from "axios";
import router from "@/router/router";
import ROLES from "@/enums/Roles";
import { isAllowed } from "@/services/AuthService";

/**
 * This is a bit complicated because some of the data e.g. roles are stored in the JWT and some others are
 * accessible through profile endpoint on the REST API.
 *
 * In order not to make too many requests, these values are stored in the localstorage and fetched only when needed.
 */
export default {
  namespaced: true,
  state: {
    email: null,
    accountId: null,
    userId: null,
    firstName: null,
    lastName: null,
    organization: null,
    token: null,
    expiration: null,
    roles: [],
  },
  mutations: {
    setToken(state, token) {
      // Either update token from database or set it
      if (token != null) {
        // Check validity
        const decoded = jwt.decode(token);
        let expiration = decoded.exp * 1000;
        if (new Date().getTime() < expiration) {
          state.accountId = decoded.sub;
          state.email = decoded.accountEmail;
          state.firstName = decoded.firstName;
          state.lastName = decoded.lastName;
          state.organization = decoded.organization;
          state.userId = decoded.userId;
          state.expiration = expiration;
          state.token = token;
          let roles = JSON.parse(decoded.roles);
          if (roles[process.env.VUE_APP_NAME]) {
            state.roles = roles[process.env.VUE_APP_NAME].map((role) => {
              return Object.values(ROLES).find((appRole) => appRole.name === role);
            });
          }
        }
      }
    },
    clear(state) {
      state.email = null;
      state.token = null;
      state.roles = [];
      state.firstName = null;
      state.lastName = null;
      state.organization = null;
      state.expiration = null;
      state.accountId = null;
      state.userId = null;
    },
  },
  actions: {
    init({ commit, dispatch }) {
    },
    login({ commit, dispatch }) {
      // Await the token from IDM
      return axios
        .create()
        .get(`${process.env.VUE_APP_IDM_SHIB_JWT_URL}/api/auth/jwt`, {
          withCredentials: true,
          headers: { Accept: "application/json" },
        })
        .then((response) => {
          if (response.data.token != null) {
            commit("setToken", response.data.token);
            Vue.prototype.$notifications.notify({
              message: "La connexion a réussi!",
              title: "Succès",
              icon: "fas fa-check",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "success",
              timeout: 5000,
            });
          } else {
            throw new Error("No shibboleth session");
          }
        })
        .catch((error) => {
        });
    },
    async logoutShib() {
      return await axios
        .create()
        .get(`${process.env.VUE_APP_SP_URL}/Shibboleth.sso/Logout`, {
          withCredentials: true,
        })
        .then(() => {
          console.log("Logged out from Shibboleth too");
        })
        .catch((error) => {
        })
    },
    logout({ commit, dispatch }) {
      commit("clear");
      dispatch('logoutShib').then((res) => {
        console.log("Logged out completely !");
      })
      .catch((error) => {
      })
      .finally(() => {
        Vue.prototype.$notifications.notify({
          message: "Vous avez correctement été déconnecté de l'application ! Pour compléter la déconnexion, vous devez quitter votre navigateur !",
          title: "Succès",
          icon: "fas fa-check",
          horizontalAlign: "right",
          verticalAlign: "bottom",
          type: "success",
          timeout: 5000,
        });
        // Check if the user still has access to the current route
        if (!isAllowed(router.currentRoute)) {
          router.push('/login');
        }
      });
    },
  },
  getters: {
    email: (state) => state.email,
    accountId: (state) => state.accountId,
    userId: (state) => state.userId,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    organization: (state) => state.organization,
    roles: (state) => state.roles,
    token: (state) => state.token,
    expiration: (state) => state.expiration,
  },
};
