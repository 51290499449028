<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          aria-label="Minimizer/Maximizer la barre de navigation"
          class="btn btn-icon btn-round"
          @click="minimizeSidebar"
        >
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar"></navbar-toggle-button>
      </div>
    </div>

    <template slot="navbar-menu">
      <li class="nav-item">
        <el-tooltip content="Deconnexion" :open-delay="300" placement="bottom">
          <a
            aria-label="Se connecter"
            href="#"
            class="nav-link btn-magnify"
            @click.prevent="logout"
          >
            <i class="fa fa-sign-in-alt" />
          </a>
        </el-tooltip>
      </li>
      <li class="nav-item">
        <el-tooltip content="Paramètres" :open-delay="300" placement="bottom">
          <el-popover placement="bottom" width="200" trigger="click">
            <div class="toggle w-100 p-2 pointer" @click="toggleDyslexicMode()">
              <p class="m-0 d-inline">Mode dyslexique</p>
              <div class="border border-dark pull-right text-center checkbox">
                <i
                  class="fas"
                  :class="{'fa-check': $store.getters['settings/dyslexicMode']}"
                  style="margin-top: 3px"
                />
              </div>
            </div>
            <a
              aria-label="Rechercher des ressources"
              href="#"
              class="nav-link btn-magnify"
              slot="reference"
            >
              <i class="fa fa-cog" />
            </a>
          </el-popover>
        </el-tooltip>
      </li>
    </template>
  </navbar>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components/Template";
import { Popover, Tooltip } from "element-ui";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip
  },

  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      basketSize: 0,
      query: "",
      colorblindMode: false,
    };
  },
  created() {},
  methods: {
    logout() {
      this.$store.dispatch("account/logout");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    toggleDyslexicMode() {
      this.$store.commit(
        "settings/setDyslexicMode",
        !this.$store.getters["settings/dyslexicMode"]
      );
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    }
  }
};
</script>
<style scoped>
.navbar .nav-item .nav-link {
  padding-top: 5px;
}
.login {
  padding: 5px 10px;
  margin: 0px 0px 0px 5px;
}
.search-icon:hover {
  cursor: pointer;
}
.toggle:hover {
  background-color: rgba(244, 244, 244, 0.496);
}
.toggle:active {
  background-color: rgba(227, 227, 227, 0.496);
}
.checkbox {
  width: 20px;
  height: 20px;
}
</style>
